<template>
  <div class="container-terms">
    <v-container style="max-width: 1200px">
      <v-row class="align-center ma-0 py-10 d-flex justify-space-between">
        <div class="align-center d-flex">
          <img :src="googleAdsLogo" @click="clickAdLogo" />
          <a
            href="/privacy-policy"
            class="ml-16 pl-16 mt-2 unvisible-in-mobile"
            >{{ $t("login_card.agree_policy_text_link") }}</a
          >
        </div>
        <!--        <select-language-component :authorized="true" />-->
      </v-row>
      <v-row>
        <v-col md="12">
          <h1>{{ $t("terms_page.title") }}</h1>
        </v-col>
        <v-col md="12">
          <v-row style="border-top: 1px solid #000000">
            <v-col md="3"
              ><span>{{ $t("terms_page.update_date") }}</span></v-col
            >
            <v-col md="7">
              <p>{{ $t("terms_page.p1") }}</p>
              <p>{{ $t("terms_page.p2") }}</p>
              <p>{{ $t("terms_page.p3") }}</p>
              <p>{{ $t("terms_page.p4") }}</p>
              <p>{{ $t("terms_page.p5") }}</p>
              <p>{{ $t("terms_page.headline1") }}</p>
              <p>{{ $t("terms_page.headline1_p1") }}</p>
              <p>{{ $t("terms_page.headline1_p2") }}</p>
              <p>{{ $t("terms_page.headline1_p3") }}</p>
              <p>{{ $t("terms_page.headline1_p4") }}</p>
              <p>{{ $t("terms_page.headline1_p5") }}</p>
              <p>{{ $t("terms_page.headline1_p6") }}</p>
              <p>{{ $t("terms_page.headline1_p7") }}</p>
              <p>{{ $t("terms_page.headline1_p8") }}</p>
              <p>{{ $t("terms_page.headline1_p9") }}</p>
              <p>{{ $t("terms_page.headline1_p10") }}</p>
              <p>{{ $t("terms_page.headline1_p11") }}</p>
              <p>{{ $t("terms_page.headline1_p12") }}</p>
              <p>{{ $t("terms_page.headline1_p13") }}</p>
              <p>{{ $t("terms_page.headline1_p14") }}</p>
              <p>{{ $t("terms_page.headline1_p15") }}</p>
              <p>{{ $t("terms_page.headline1_p16") }}</p>
              <p>{{ $t("terms_page.headline1_p17") }}</p>
              <p>{{ $t("terms_page.headline1_p18") }}</p>
              <p>{{ $t("terms_page.headline1_p19") }}</p>
              <p>{{ $t("terms_page.headline1_p20") }}</p>
              <p>{{ $t("terms_page.headline1_p21") }}</p>
              <h4>{{ $t("terms_page.headline2") }}</h4>
              <p>{{ $t("terms_page.headline2_p2") }}</p>
              <p>{{ $t("terms_page.headline2_p3") }}</p>
              <p>{{ $t("terms_page.headline2_p4") }}</p>
              <p>{{ $t("terms_page.headline2_p5") }}</p>
              <p>{{ $t("terms_page.headline2_p6") }}</p>
              <p>{{ $t("terms_page.headline2_p7") }}</p>
              <p>{{ $t("terms_page.headline2_p8") }}</p>
              <p>{{ $t("terms_page.headline2_p9") }}</p>
              <h4>{{ $t("terms_page.headline3") }}</h4>
              <p>{{ $t("terms_page.headline3_p1") }}</p>
              <p>{{ $t("terms_page.headline3_p2") }}</p>
              <h4>{{ $t("terms_page.headline4") }}</h4>
              <p>{{ $t("terms_page.headline4_p1") }}</p>
              <p>{{ $t("terms_page.headline4_p2") }}</p>
              <p>{{ $t("terms_page.headline4_p3") }}</p>
              <p>{{ $t("terms_page.headline4_p4") }}</p>
              <h4>{{ $t("terms_page.headline5") }}</h4>
              <p>{{ $t("terms_page.headline5_p1") }}</p>
              <p>{{ $t("terms_page.headline5_p2") }}</p>
              <p>{{ $t("terms_page.headline5_p3") }}</p>
              <p>{{ $t("terms_page.headline5_p4") }}</p>
              <p>{{ $t("terms_page.headline5_p5") }}</p>
              <h4>{{ $t("terms_page.headline6") }}</h4>
              <p>{{ $t("terms_page.headline6_p1") }}</p>
              <p>{{ $t("terms_page.headline6_p2") }}</p>
              <p>{{ $t("terms_page.headline6_p3") }}</p>
              <p>{{ $t("terms_page.headline6_p4") }}</p>
              <h4>{{ $t("terms_page.headline7") }}</h4>
              <p>{{ $t("terms_page.headline7_p1") }}</p>
              <p>{{ $t("terms_page.headline7_p2") }}</p>
              <p>{{ $t("terms_page.headline7_p3") }}</p>
              <p>{{ $t("terms_page.headline7_p4") }}</p>
              <p>{{ $t("terms_page.headline7_p5") }}</p>
              <p>{{ $t("terms_page.headline7_p6") }}</p>
              <h4>{{ $t("terms_page.headline8") }}</h4>
              <p>{{ $t("terms_page.headline8_p1") }}</p>
              <p>{{ $t("terms_page.headline8_p2") }}</p>
              <p>{{ $t("terms_page.headline8_p3") }}</p>
              <p>{{ $t("terms_page.headline8_p4") }}</p>
              <h4>{{ $t("terms_page.headline9") }}</h4>
              <p>{{ $t("terms_page.headline9_p1") }}</p>
              <p>{{ $t("terms_page.headline9_p2") }}</p>
              <p>{{ $t("terms_page.headline9_p3") }}</p>
              <h4>{{ $t("terms_page.headline10") }}</h4>
              <p>{{ $t("terms_page.headline10_p1") }}</p>
              <p>{{ $t("terms_page.headline10_p2") }}</p>
              <p>{{ $t("terms_page.headline10_p3") }}</p>
              <h4>{{ $t("terms_page.headline11") }}</h4>
              <p>{{ $t("terms_page.headline11_p1") }}</p>
              <p>{{ $t("terms_page.headline11_p2") }}</p>
              <p>{{ $t("terms_page.headline11_p3") }}</p>
              <h4>{{ $t("terms_page.headline12") }}</h4>
              <p>{{ $t("terms_page.headline12_p1") }}</p>
              <h4>{{ $t("terms_page.headline13") }}</h4>
              <p>{{ $t("terms_page.headline13_p1") }}</p>
              <h4>{{ $t("terms_page.headline14") }}</h4>
              <p>{{ $t("terms_page.headline14_p1") }}</p>
              <h4>{{ $t("terms_page.headline15") }}</h4>
              <p>{{ $t("terms_page.headline15_p1") }}</p>
              <p>{{ $t("terms_page.headline15_p2") }}</p>
              <p>{{ $t("terms_page.headline15_p2_foot") }}</p>
              <h5>{{ $t("terms_page.headline15_p3_title") }}</h5>
              <p>{{ $t("terms_page.headline15_p3_span1") }}</p>
              <p>{{ $t("terms_page.headline15_p3_span2") }}</p>
              <p>{{ $t("terms_page.headline15_p3_span3") }}</p>
              <p>{{ $t("terms_page.headline15_p3_span4") }}</p>
              <p>{{ $t("terms_page.headline15_p3_span5") }}</p>
              <p>{{ $t("terms_page.headline15_p4") }}</p>
              <p>{{ $t("terms_page.headline15_p5") }}</p>
              <h4>{{ $t("terms_page.headline16") }}</h4>
              <p>{{ $t("terms_page.headline16_p1") }}</p>
              <p>{{ $t("terms_page.headline16_p2") }}</p>
              <p>{{ $t("terms_page.headline16_p3") }}</p>
              <p>{{ $t("terms_page.headline16_p4") }}</p>
              <p>{{ $t("terms_page.headline16_p5") }}</p>
              <p>{{ $t("terms_page.headline16_p6") }}</p>
              <p>{{ $t("terms_page.headline16_p7") }}</p>
              <p>{{ $t("terms_page.headline16_p8") }}</p>
              <p>{{ $t("terms_page.headline16_p9") }}</p>
              <div>
                <div>{{ $t("terms_page.footer_p1") }}</div>
                <div>{{ $t("terms_page.footer_p2") }}</div>
                <p>{{ $t("terms_page.footer_p3") }}</p>
              </div>
              <p class="pb-16">{{ $t("terms_page.support_email") }}</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <Footer bg-color="#b9b9b9" />
  </div>
</template>

<script>
import googleAdsLogo from "@/assets/icon/Pivotadd_black.svg";

export default {
  name: "TermsConditions",
  components: {
    Footer: () => import("@/components/Footer"),
    // SelectLanguageComponent: () => import("@/components/SelectLanguageComponent")
  },
  data() {
    return {
      googleAdsLogo,
    };
  },
  methods: {
    clickAdLogo() {
      const token = localStorage.getItem("token");
      if (token) {
        this.$router.push("/my-campaigns");
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped>
.container-terms {
  background: #f2f2f7 !important;
  font-family: "Raleway light", sans-serif !important;
  font-variant-numeric: lining-nums !important;
}
@media screen and (max-width: 450px) {
  .unvisible-in-mobile {
    display: none;
  }
}
</style>
